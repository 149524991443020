<template>
  <Fragment>
    <v-row>
      <v-col md="12" cols="12">
        <v-alert :type="typeMensajeAlerta">
          {{ mensajeAlerta }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if=" total ">
      <v-col cols="12" md="8">
        <v-autocomplete
            label="Nueva modalidad"
            v-model="nuevaModalidad"
            :items="modalidades"
            item-text="nombre"
            item-value="id"
            outlined
            clearable
            hide-details
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-btn class="blue-grey--text " outlined @click="cambiarModalidad">Realizar cambio modalidad</v-btn>
      </v-col>
    </v-row>
    <FeedbackModalComponent
        type="warning"
        title="Cambio de modalidad no es posible"
        :description="feedbackModal.description"
        :is-open="feedbackModal.show"
        button-text="Aceptar"
        @on-close="handleFeedbackClose"
    />
  </Fragment>
</template>
<script>
import {FeedbackModalComponent} from '@/components/utils';
import {Fragment} from 'vue-fragment';

export default {
  name: 'cambioModalidadPac',
  components: {
    Fragment,
    FeedbackModalComponent,
  },
  props: {
    modalidadActual: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      detalle: [],
      modalidades: [],
      total: true,
      nuevaModalidad: null,
      headers: [
        {
          text: 'Detalle',
          value: 'titulo',
        },
        {
          text: 'Conteo',
          value: 'conteo',
        },
        {
          text: 'Resumen',
          value: 'resumen',
        }
      ],
      feedbackModal: {
        show: false,
        description: '',
      },
    }
  },
  methods: {
    async obtenerModalidadesCambio() {
      const {
        data: {
          forma_contratacion
        }
      } = await this.services.Paac.getModalidades({
        solo_migracion: true,
      })
      this.modalidades = forma_contratacion
    },
    async validadCambioModalidad() {
      this.detalle = []
      const {
        data: {
          procesoValidoCambioModalidad
        }
      } = await this.services.Paac.validarCambioModalidad(this.$route.params.idProceso);
      this.total = procesoValidoCambioModalidad
    },
    async cambiarModalidad() {
      if (!this.nuevaModalidad) {
        return this.pushAppMessage({type: "warning", message: "Debe seleccionar una modalidad."});
      }

      if (this.nuevaModalidad === this.modalidadActual) {
        return this.pushAppMessage({type: "warning", message: "La modalidad seleccionada es la misma que la actual."});
      }

        const {data} = await this.services.Paac.cambiarModalidad(this.$route.params.idProceso, {
          nueva_modalidad: this.nuevaModalidad
        });
        this.$emit('cambioModalidad')
        this.pushAppMessage({type: "success", message: data.message});
    },
    handleFeedbackClose() {
      this.feedbackModal = {
        description: '',
        show: false,
      }
    },
  },
  computed: {
    mensajeAlerta() {
      return !this.total ? 'No puede aplicar a cambio de modalidad, solo puede aplicar si se encuentra el seguimiento en Configuración de proceso o recepción de solicitudes' :
          'Puede aplicar a cambio de modalidad.'
    },
    typeMensajeAlerta() {
      return !this.total ? 'warning' : 'info'
    }
  },
  async mounted() {
    await this.validadCambioModalidad()
    await this.obtenerModalidadesCambio()
  }
}
</script>